import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { theme } from './theme';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import './theme/global.css';
import { setContext } from '@apollo/client/link/context';
import { Auth } from "./settings/common";
import {API_URL} from "./settings/api-url"

const httpLink = createHttpLink({
  uri: API_URL
});

declare global {
  interface Array<T> {
    groupBy(elem: T): any;
  }
  interface Number {
    toMoney(needFraction?: boolean): any;
  }
}

Array.prototype.groupBy = function (key) {
  return this.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      },
      {});
};

Number.prototype.toMoney = function (needFraction = true) {
  var parts = this.toFixed(2).split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${needFraction? parts.join(".") : parts[0]}`;
};

function App() {

  const authLink = setContext((_, { headers }) => {
    const authData = Auth.get();
    return {
      headers: {
        ...headers,
        authorization: authData && authData.token ? `Bearer ${authData.token}` : ''
      }
    };
  });



  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  const engine = new Styletron();

  return (
    <ApolloProvider client={client as any}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={theme}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </BaseProvider>
      </StyletronProvider>
    </ApolloProvider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
