// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = '/';
export const LOGIN = '/login';

export const RIDES_SETTINGS = '/settings/ride';
export const DEVICE_SETTINGS = '/settings/device';
export const COUNTER_SETTINGS = '/settings/counter';
export const EMPLOYEE_COUNTER ='/settings/employee-counter';
export const ACCESS_POINT_SETTINGS = '/settings/access-point';
export const ADD_PACKAGE_SETTINGS = '/settings/package/add';
export const PACKAGE_SETTINGS = '/settings/package';
export const USER_SETTINGS = '/settings/users';
export const PRODUCT_SETTINGS = '/settings/product';
export const WRISTBAND_SETTINGS = '/settings/wristband'
export const SETTINGS = '/settings';

export const ACCESS_POINT = '/access-point';

export const TICKET_COUNTER_INVOICES = '/ticket-counter/invoices';
export const TICKET_COUNTER_TOPUP = '/counter/top-up';
export const TICKET_COUNTER='/ticket-counter';

export const COUNTER_INVOICES = '/counter/invoices';
export const COUNTER_WRISTBAND_FINE = "/counter/wristband-fine";
export const COUNTER_WRISTBAND_RETURN = '/counter/wristband-return';
export const COUNTER_RENT_RETURN = '/counter/rent-return';
export const COUNTER = '/counter';

export const OPERATION_DAILY_REPORT_PRINT = "/counter-operation/daily-report/print"
export const OPERATION_DAILY_REPORT = "/counter-operation/daily-report"
export const OPERATION_TRANSACTION_REPORT = '/counter-operation/transaction-report';
export const OPERATION_ADD_TRANSACTION = '/counter-operation/transaction-add'
export const OPERATION_SUPPLIER ='/counter-operation/supplier';
export const OPERATION_EXPENSE ='/counter-operation/expense';
export const OPERATION_BANK ='/counter-operation/bank';
export const OPERATION_INVENTORY_TRANSFER ='/counter-operation/inventory-transfer';
export const OPERATION_INVENTORY ='/counter-operation/inventory';
export const COUNTER_OPERATION = '/counter-operation';

export const TC_OPERATION_DAILY_REPORT = '/ticket-counter-operation/daily-report'
export const TC_OPERATION_TRANSACTION_REPORT = '/ticket-counter-operation/transaction-report';
export const TC_OPERATION_ADD_TRANSACTION = '/ticket-counter-operation/transaction-add'
export const TC_OPERATION_EXPENSE ='/ticket-counter-operation/expense';
export const TC_OPERATION_BANK ='/ticket-counter-operation/bank';
export const TICKET_COUNTER_OPERATION = '/ticket-counter-operation';

export const PRODUCT='/product';
export const SUPPLIER ='/supplier';
export const PACKAGE_DETAILS='/package-details';
export const INVOICE_PRINT='/invoice-print';
export const CUSTOMER_STATEMENT_PRINT = "/customer-invoice-print"
export const TOP_UP='/top-up';
export const RIDES_CHECKER = '/ride-checker';
export const LOCKER = '/locker';

export const CUSTOMER_WRISTBAND = '/customers-wristband'

export const TRANSACTIONS_PARK_REPORT = '/transactions/park-report'
export const TRANSACTIONS_REPORT = '/transactions/report'
export const ADD_TRANSACTIONS = '/transactions/add'
export const VIEW_CUSTOMER_STATEMENT = '/customer-statement/view'
export const TRANSACTIONS = '/transactions'

// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '৳';
export const AUTH_DATA = "7c542d0b-e857-4c52-a54f-59585b43a52e";
export const TABLE_PAGE_SIZE = 10;
