﻿function parseBaseUrl() {

    const host = window.location.host;
    if (host.startsWith("localhost")) {
        return "https://localhost:44326/graphql";
    } else {
        let arr = window.location.host.split(".");
        if (host.endsWith("azurewebsites.net")) {
            arr[0] = arr[0] + "-api";
        } else {
            arr[0] = "api-" + arr[0];
        }

        return `${window.location.protocol}//${arr.join(".")}/graphql`;
    }
}

export const API_URL = parseBaseUrl();
